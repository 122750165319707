import Vue from "vue";
import VueRouter from "vue-router";
import answer from "@/views/answer.vue";
import collect from "@/views/collect.vue";
import collectStudent from "@/views/collect-student.vue";
import informationDetail from "@/views/information-detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/data/collect",
    name: "CollectData",
    component: collect,
  },
  {
    path: "/student-data/collect",
    name: "CollectStudentData",
    component: collectStudent,
  },
  {
    path: "/information/detail/:id?",
    name: "InformationDetail",
    component: informationDetail,
  },
  {
    path: "/:id?",
    name: "Evaluation",
    component: answer,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
